import React, {CSSProperties, FC} from "react";
import { useAppDispatch } from "../../app/hooks";
import { InputText } from "primereact/inputtext";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import {InputTextarea} from "primereact/inputtextarea";

type TTextAreaInputProps = {
  id: string;
  name: string;
  value: string;
  label: string;
  setProperty: ActionCreatorWithPayload<any>;
  style?: CSSProperties,
  index?: number
};

const TextAreaInput: FC<TTextAreaInputProps> = ({
  id,
  name,
  value,
  label,
  setProperty,
    style,
                                          index,
}) => {
  const dispatch = useAppDispatch();

  return (
    <>
      <div className="field pt-2 pb-2 w-full">
        <span className="p-float-label">
          <InputTextarea
            id={id}
            value={value}
            onChange={(e) =>
              dispatch(setProperty({ property: name, value: e.target.value, index: index }))
            }
            style={style}
            className={"w-full"}
          />
          <label htmlFor={name}>{label}</label>
        </span>
      </div>
    </>
  );
};

export default TextAreaInput;
