import React, {FC, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "primereact/button";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    initialDropState,
    selectDropState,
    setDropData,
    setDropProperty,
    setDropShopProperty,
    removeDropShop,
    addDropShop
} from "../../slices/dropsSlice";
import {routes} from "../../app/routes";
import {
    useCreateDropMutation,
    useEditDropMutation,
    useFindDropByIdQuery,
} from "../../api/grailzApi";
import TextInput from "../../components/form/TextInput";
import UploadImageInput from "../../components/form/UploadImageInput";
import DateInput from "../../components/form/DateInput";
import {DropShopDto} from "../../api/grailzApi";
import TableWrapper from "../../components/table/TableWrapper";
import {Image} from "primereact/image";
import {Dialog} from "primereact/dialog";
import {PrimeIcons} from "primereact/api";
import TextAreaInput from "../../components/form/TextAreaInput";
import SelectInput from "../../components/form/SelectInput";

const confirmedStartDateOptions = [
    {value: true, label: "Potwierdzona"},
    {value: false, label: "Niepotwierdzona"},
];

const glowOptions = [
    {value: true, label: "Tak"},
    {value: false, label: "Nie"},
];

const UpdateDrop: FC<{}> = ({}) => {
    const {id = ""} = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {isLoading: isPrepareLoading} = useFindDropByIdQuery(
        {id: id},
        {skip: id === ""}
    );
    const [
        updateDrop,
        {isLoading: isUpdateLoading, isSuccess: isUpdateSuccess},
    ] = useEditDropMutation();
    const [
        createDrop,
        {isLoading: isCreateLoading, isSuccess: isCreateSuccess},
    ] = useCreateDropMutation();

    const {drop, currentIndex} = useAppSelector(selectDropState);
    const {
        title,
        description,
        price,
        resellPrice,
        publishedDate,
        startDate,
        confirmedStartDate,
        expiredDate,
        imageUrl,
        referralLink,
        sku,
        colorWay,
        priority,
        shops,
        glow,
    } = drop;

    const isSuccess = isUpdateSuccess || isCreateSuccess;

    const [visible, setVisible] = React.useState(false);

    useEffect(() => {
        dispatch(setDropData({property: "drop", value: initialDropState}));

        if (isSuccess) {
            navigate(`/${routes.DROPS}`);
        }
    }, [navigate, isSuccess]);

    const onSubmit = () => {
        !!id
            ? updateDrop({id: id, dropRequest: drop})
            : createDrop({dropRequest: drop});
    };

    return (
        <>
            <Dialog
                visible={visible}
                onHide={() => {
                    setVisible(false);
                }}
                header="Dodaj sklep"
                style={{width: "50vw"}}
                footer={
                    <Button
                        onClick={() => {
                            setVisible(false);
                        }}
                        label="Dodaj"
                    />
                }
            >
                <DateInput
                    id="date"
                    name="date"
                    value={shops[currentIndex]?.date}
                    index={currentIndex}
                    label="Data"
                    dateFormat="yy-mm-dd"
                    setProperty={setDropShopProperty}
                />
                <TextInput
                    id="url"
                    name="url"
                    label="Adres do sklepu"
                    value={shops[currentIndex]?.url}
                    index={currentIndex}
                    setProperty={setDropShopProperty}
                    style={{width: "100%"}}
                />
                <UploadImageInput
                    id="imageUrlShop"
                    name="imageUrl"
                    value={shops[currentIndex]?.imageUrl}
                    index={currentIndex}
                    label="Załaduj ikonę"
                    setProperty={setDropShopProperty}
                />
            </Dialog>
            <div className="grid m-5">
                <h3 className="text-center p-1 mt-0">
                    {!id ? "Dodaj dropa" : `Edytuj dropa ${title}`}
                </h3>
                <TextInput
                    id="title"
                    name="title"
                    value={title}
                    label="Tytuł"
                    setProperty={setDropProperty}
                />
                <TextAreaInput
                    id="description"
                    name="description"
                    value={description}
                    label="Opis"
                    setProperty={setDropProperty}
                />
                <TextInput
                    id="price"
                    name="price"
                    value={price}
                    label="Retail price"
                    setProperty={setDropProperty}
                />
                <TextInput
                    id="resellPrice"
                    name="resellPrice"
                    value={resellPrice}
                    label="Resell price"
                    setProperty={setDropProperty}
                />
                <DateInput
                    id="publishedDate"
                    name="publishedDate"
                    value={publishedDate}
                    label="Data publikacji dropa"
                    dateFormat="yy-mm-dd"
                    setProperty={setDropProperty}
                />
                <DateInput
                    id="startDate"
                    name="startDate"
                    value={startDate}
                    label="Data premiery (widoczna w aplikacji mobilnej)"
                    dateFormat="yy-mm-dd"
                    setProperty={setDropProperty}
                />
                <SelectInput
                    id="confirmedStartDate"
                    options={confirmedStartDateOptions}
                    name="confirmedStartDate"
                    value={confirmedStartDate}
                    label="Data premiery potwierdzona?"
                    setProperty={setDropProperty}
                />
                <DateInput
                    id="expiredDate"
                    name="expiredDate"
                    value={expiredDate}
                    label="Data wygaśnięcia dropa"
                    dateFormat="yy-mm-dd"
                    setProperty={setDropProperty}
                />
                <UploadImageInput
                    id="imageUrl"
                    name="imageUrl"
                    value={imageUrl}
                    label="Zdjęcie"
                    setProperty={setDropProperty}
                />
                <TextInput
                    id="referralLink"
                    name="referralLink"
                    value={referralLink}
                    label="Link referencyjny"
                    setProperty={setDropProperty}
                />
                <TextInput
                    id="sku"
                    name="sku"
                    value={sku}
                    label="SKU"
                    setProperty={setDropProperty}
                />
                <TextInput
                    id="colorWay"
                    name="colorWay"
                    value={colorWay}
                    label="Color way"
                    setProperty={setDropProperty}
                />
                <TextInput
                    id="priority"
                    name="priority"
                    value={priority}
                    label="Priorytet (na ten moment nieużywany)"
                    setProperty={setDropProperty}
                />
                <SelectInput
                    id="glow"
                    options={glowOptions}
                    name="glow"
                    value={glow}
                    label="Zastosuj glow"
                    setProperty={setDropProperty}
                />

                <div className="w-full">
                    <TableWrapper
                        header="Sklepy"
                        isLoading={false}
                        data={shops}
                        columns={[
                            {field: "date", header: "Data"},
                            {field: "url", header: "Url"},
                            {
                                field: "imageUrl",
                                header: "Ikona",
                                body: (row: DropShopDto) => <Image src={row.imageUrl} width="50" height="50"/>,
                            },
                            {
                                field: "",
                                header: "",
                                body: (data: DropShopDto, props) => (
                                    <Button icon={PrimeIcons.TRASH} onClick={() => {
                                        dispatch(removeDropShop({index: props.rowIndex}));
                                    }
                                    }/>
                                ),
                            },
                        ]}
                        addButton={
                            <Button onClick={() => {
                                dispatch(addDropShop())
                                setVisible(true);
                            }} label="Dodaj"/>
                        }
                    />
                </div>

                <Button label="Zapisz" className="mt-5 w-full" onClick={onSubmit}/>
            </div>
        </>
    );
};

export default UpdateDrop;
