import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CustomNotificationRequest} from "../api/grailzApi";

type TNotificationState = {
    data: CustomNotificationRequest;
};

export const initialCustomNotificationState: CustomNotificationRequest = {
    title: '',
    subTitle: '',
    description: '',
    imageUrl: ''
}

const initialState: TNotificationState = {
    data: initialCustomNotificationState
};

export const notificationsSLice = createSlice({
    name: "NOTIFICATION",
    initialState,
    reducers: {
        setData: <Property extends keyof TNotificationState>(
            state: TNotificationState,
            {
                payload,
            }: PayloadAction<{ property: Property; value: TNotificationState[Property] }>
        ) => {
            const {property, value} = payload;
            state[property] = value;
        },
        setDataProperty: <Property extends keyof CustomNotificationRequest>(
            state: TNotificationState,
            {
                payload,
            }: PayloadAction<{ property: Property; value: CustomNotificationRequest[Property] }>
        ) => {
            const {property, value} = payload;
            state.data[property] = value;
        },
    },
});

export const {setData, setDataProperty} = notificationsSLice.actions;

export const selectNotificationState = (state: any) => state.notifications;
export const selectNotificationDataState = (state: any) => state.notifications.data;

export default notificationsSLice.reducer;
