import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {WtbRequest} from "../api/grailzApi";

type TWtbState = {
    wtb: WtbRequest;
};

export const initialWtbState: WtbRequest = {
    name: "",
    imageUrl: "",
    priority: 0,
}

const initialState: TWtbState = {
    wtb: initialWtbState,
};

export const wtbsSlice = createSlice({
    name: "WTB",
    initialState,
    reducers: {
        setWtbData: <Property extends keyof TWtbState>(
            state: TWtbState,
            {
                payload,
            }: PayloadAction<{ property: Property; value: TWtbState[Property] }>
        ) => {
            const {property, value} = payload;
            state[property] = value;
        },
        setWtbProperty: <Property extends keyof WtbRequest>(
            state: TWtbState,
            {
                payload,
            }: PayloadAction<{ property: Property; value: WtbRequest[Property] }>
        ) => {
            const {property, value} = payload;
            state.wtb[property] = value;
        }
    },
});

export const {setWtbData, setWtbProperty} = wtbsSlice.actions;

export const selectWtbState = (state: any) => state.wtbs;

export default wtbsSlice.reducer;
