import {BannerResponse} from "../api/grailzApi";

export const recognizeLabel = (type: BannerResponse["type"]): string => {
    switch(type) {
        case "AFFILIATE_1": 
            return "Główny #1";
        case "AFFILIATE_2":
            return "Po kliknięciu w główny #1";
        case "AFFILIATE_1_2":
            return "Główny #2";
        case "AFFILIATE_2_2":
            return "Po kliknięciu w główny #2";
        case "AFFILIATE_3":
            return "Mały kwadrat #1";
        case "AFFILIATE_3_2":
            return "Mały kwadrat #2";
        case "AFFILIATE_3_3":
            return "Mały kwadrat #3";
        case "AFFILIATE_3_4":
            return "Mały kwadrat #4";
        case "AFFILIATE_3_5":
            return "Mały kwadrat #5";
        case "AFFILIATE_7":
            return "Slider #1";
        case "AFFILIATE_7_2":
            return "Slider #2";
        case "AFFILIATE_7_3":
            return "Slider #3";
        case "AFFILIATE_7_4":
            return "Slider #4";
        case "AFFILIATE_7_5":
            return "Slider #5";
        case "AFFILIATE_8":
            return "Prostokąt #1";
        case "AFFILIATE_8_2":
            return "Prostokąt #2";
        case "AFFILIATE_8_3":
            return "Prostokąt #3";
        case "AFFILIATE_8_4":
            return "Prostokąt #4";
        case "AFFILIATE_8_5":
            return "Prostokąt #5";
        case "AFFILIATE_8_6":
            return "Prostokąt #6";
        case "AFFILIATE_8_7":
            return "Prostokąt #7";
        case "AFFILIATE_8_8":
            return "Prostokąt #8";
        case "AFFILIATE_8_9":
            return "Prostokąt #9";
        case "AFFILIATE_8_10":
            return "Prostokąt #10";
        case "AFFILIATE_8_11":
            return "Prostokąt #11";
        case "AFFILIATE_8_12":
            return "Prostokąt #12";
        case "AFFILIATE_8_13":
            return "Prostokąt #13";
        case "AFFILIATE_8_14":
            return "Prostokąt #14";
        case "AFFILIATE_8_15":
            return "Prostokąt #15";
        case "AFFILIATE_9":
            return "Slider z progresem #1";
        case "AFFILIATE_9_2":
            return "Slider z progresem #2";
        case "AFFILIATE_9_3":
            return "Slider z progresem #3";
        case "AFFILIATE_9_4":
            return "Slider z progresem #4";
        case "AFFILIATE_9_5":
            return "Slider z progresem #5";
        case "BARGAIN_1":
            return "Główny";
        case "BARGAIN_4":
            return "Po kliknięciu w główny";
        case "BARGAIN_2":
            return "Prostokąt 1 #1";
        case "BARGAIN_2_2":
            return "Prostokąt 1 #2";
        case "BARGAIN_2_3":
            return "Prostokąt 1 #3";
        case "BARGAIN_2_4":
            return "Prostokąt 1 #4";
        case "BARGAIN_2_5":
            return "Prostokąt 1 #5";
        case "BARGAIN_3":
            return "Mały kwadrat #1";
        case "BARGAIN_3_2":
            return "Mały kwadrat #2";
        case "BARGAIN_3_3":
            return "Mały kwadrat #3";
        case "BARGAIN_3_4":
            return "Mały kwadrat #4";
        case "BARGAIN_3_5":
            return "Mały kwadrat #5";
        case "BARGAIN_3_6":
            return "Mały kwadrat #6";
        case "BARGAIN_3_7":
            return "Mały kwadrat #7";
        case "BARGAIN_3_8":
            return "Mały kwadrat #8";
        case "BARGAIN_3_9":
            return "Mały kwadrat #9";
        case "BARGAIN_3_10":
            return "Mały kwadrat #10";
        case "BARGAIN_7":
            return "Prostokąt 2 #1";
        case "BARGAIN_7_2":
            return "Prostokąt 2 #2";
        case "BARGAIN_7_3":
            return "Prostokąt 2 #3";
        case "BARGAIN_7_4":
            return "Prostokąt 2 #4";
        case "BARGAIN_7_5":
            return "Prostokąt 2 #5";
        case "BARGAIN_8":
            return "Slider #1";
        case "BARGAIN_8_2":
            return "Slider #2";
        case "BARGAIN_8_3":
            return "Slider #3";
        case "BARGAIN_8_4":
            return "Slider #4";
        case "BARGAIN_8_5":
            return "Slider #5";
        case "BARGAIN_9":
            return "Prostokąt 3 #1";
        case "BARGAIN_9_2":
            return "Prostokąt 3 #2";
        case "BARGAIN_9_3":
            return "Prostokąt 3 #3";
        case "BARGAIN_9_4":
            return "Prostokąt 3 #4";
        case "BARGAIN_9_5":
            return "Prostokąt 3 #5";
        case "GRAIL_SHOP_1":
            return "Baner sklepu nr 1";
        case "GRAIL_SHOP_2":
            return "Baner sklepu nr 2";
        case "GRAIL_SHOP_3":
            return "Baner sklepu nr 3";
        case "GRAIL_SHOP_4":
            return "Baner sklepu nr 4";
        case "GRAIL_SHOP_5":
            return "Baner główny sklepu";
        case "GRAIL_SHOP_6":
            return "Baner promocyjny w sklepie";
        default:
            return "Nieznane";
    }
}