import React, {FC, useEffect} from "react";
import TableWrapper from "../../components/table/TableWrapper";
import productActionTemplate from "./Actions";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    useCreateProductMutation,
    useFindAllProductsQuery,
} from "../../api/grailzApi";
import {selectProductState, setProductData} from "../../slices/productsSlice";
import TextInput from "../../components/form/TextInput";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {ProgressSpinner} from "primereact/progressspinner";
import {toast} from "react-toastify";

const columns = [
    {field: "name", header: "Nazwa"},
    {field: "", header: "", body: productActionTemplate},
];

const Products: FC<{}> = ({}) => {
    const dispatch = useAppDispatch();

    const {page, size, offset, totalSize, filter, address} =
        useAppSelector(selectProductState);

    const {isLoading, data} = useFindAllProductsQuery({
        page: page,
        size: size,
        queryText: filter,
    });

    const [addProduct, {
        isLoading: isCreateProductLoading,
        isSuccess: isCreateProductSuccess,
        isError: isCreateProductError
    }] = useCreateProductMutation();

    const [visible, setVisible] = React.useState(false);

    const addButton = <Button onClick={() => setVisible(true)} label="Dodaj"/>;

    const clearAddressValue = () => {
        dispatch(setProductData({property: "address", value: ""}));
    };

    useEffect(() => {
        if (isCreateProductSuccess || isCreateProductError) {
            setVisible(false);
            clearAddressValue();
            if (isCreateProductSuccess) {
                toast.success("Produkt został dodany!");
            } else if (isCreateProductError) {
                toast.error("Wystąpił problem z dodaniem produktu!");
            }
        }
    }, [isCreateProductSuccess, isCreateProductError]);

    return (
        <>
            <Dialog
                visible={visible}
                onHide={() => {
                    setVisible(false);
                    clearAddressValue();
                }}
                header="Dodaj produkt"
                style={{width: "50vw"}}
                footer={
                    <Button
                        onClick={() => {
                            addProduct({
                                createProductRequest: {source: "STOCKX", url: address},
                            });
                        }}
                        disabled={isCreateProductLoading}
                        label="Dodaj"
                    />
                }
            >
                {isCreateProductLoading ?
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}><ProgressSpinner/>
                    </div> : <TextInput
                        id="address"
                        name="address"
                        label="Link do StockX"
                        value={address}
                        setProperty={setProductData}
                        style={{width: "100%"}}
                    />}

            </Dialog>
            <TextInput
                id="filter-product"
                name="filter"
                label=""
                value={filter}
                setProperty={setProductData}
            />
            <TableWrapper
                header="Produkty"
                sortField="name"
                isLoading={isLoading}
                data={data?.content}
                columns={columns}
                paginator
                page={page}
                first={offset}
                size={size}
                totalElements={totalSize}
                setData={setProductData}
                addButton={addButton}
            />
        </>
    );
};

export default Products;
