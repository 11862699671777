import React, { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  useCreateWtbMutation,
  useEditWtbMutation,
  useFindWtbByIdQuery,
} from "../../api/grailzApi";
import {
  initialWtbState,
  selectWtbState,
  setWtbData,
  setWtbProperty,
} from "../../slices/wtbsSlice";
import { routes } from "../../app/routes";
import TextInput from "../../components/form/TextInput";
import UploadImageInput from "../../components/form/UploadImageInput";

const UpdateWtb: FC<{}> = ({}) => {
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoading: isPrepareLoading } = useFindWtbByIdQuery(
    { id: id },
    { skip: id === "" }
  );
  const [
    updateWtb,
    { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess },
  ] = useEditWtbMutation();
  const [
    createWtb,
    { isLoading: isCreateLoading, isSuccess: isCreateSuccess },
  ] = useCreateWtbMutation();

  const { wtb } = useAppSelector(selectWtbState);
  const { name, imageUrl, priority } = wtb;

  const isSuccess = isUpdateSuccess || isCreateSuccess;

  useEffect(() => {
    dispatch(setWtbData({ property: "wtb", value: initialWtbState }));

    if (isSuccess) {
      navigate(`/${routes.WTBS}`);
    }
  }, [navigate, isSuccess]);

  const onSubmit = () => {
    !!id
      ? updateWtb({ id: id, wtbRequest: wtb })
      : createWtb({ wtbRequest: wtb });
  };

  return (
    <>
      <div className="grid m-5">
        <h3 className="text-center p-1 mt-0">
          {!id
            ? `Dodaj WTB`
            : `Aktualizuj WTB ${name}`}
        </h3>
        <TextInput
          id="name"
          name="name"
          value={name}
          label="Nazwa"
          setProperty={setWtbProperty}
        />
        <TextInput
          id="priority"
          name="priority"
          value={priority}
          label="Priorytet (czym wyższy priorytet, tym WTB znajduje się wyżej w aplikacji)"
          setProperty={setWtbProperty}
        />
        <UploadImageInput
          id="imageUrl"
          name="imageUrl"
          value={imageUrl}
          label="Zdjęcie WTB"
          setProperty={setWtbProperty}
        />
        <Button label="Zapisz" className="mt-5 w-full" onClick={onSubmit} />
      </div>
    </>
  );
};

export default UpdateWtb;
