import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type TAuthState = { token: string };

const initialState: TAuthState = {
  token: '',
};

export const authSlice = createSlice({
  name: "AUTH",
  initialState,
  reducers: {
    setAuthData: <Property extends keyof TAuthState>(
      state: TAuthState,
      {
        payload,
      }: PayloadAction<{ property: Property; value: TAuthState[Property] }>
    ) => {
      const { property, value } = payload;
      state[property] = value;
    },
  },
});

export const { setAuthData } = authSlice.actions;

export const selectAuthState = (state: any) => state.auth;

export default authSlice.reducer;
