import React, { FC } from "react";
import { useAppDispatch } from "../../app/hooks";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { Image } from "primereact/image";
import { FileUpload } from "primereact/fileupload";
import keycloak from "../../Keycloak";

type TUploadImageInputProps = {
  id: string;
  name: string;
  value: string;
  label: string;
  setProperty: ActionCreatorWithPayload<any>;
  index?: number;
};

const UploadImageInput: FC<TUploadImageInputProps> = ({
  id,
  name,
  value,
  label,
  setProperty,
    index,
}) => {
  const dispatch = useAppDispatch();

  return (
    <>
      <div className="grid" style={{marginBottom: 20}}>
        <div className="col">
          <Image src={value} alt={label} width="200" />
        </div>
        <div className="col">
          <FileUpload
              id={id}
              name="image"
              accept="image/*"
              url="/api/images"
              mode="basic"
              maxFileSize={1000000}
              chooseOptions={{ label: label }}
              auto
              onUpload={(e) =>
                  dispatch(setProperty({ property: name, value: e.xhr.response, index: index }))
              }
              onBeforeSend={(e) =>
                  e.xhr.setRequestHeader("Authorization", `Bearer ${keycloak.token}`)
              }
          />
        </div>
      </div>
    </>
  );
};

export default UploadImageInput;
