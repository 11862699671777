import React, { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  initialProductState,
  selectProductState,
  setProductData,
  setProductProperty,
} from "../../slices/productsSlice";
import TextInput from "../../components/form/TextInput";
import { useFindProductByIdQuery } from "../../api/grailzApi";

const UpdateProduct: FC<{}> = ({}) => {
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoading: isPrepareLoading } = useFindProductByIdQuery(
    { id: id },
    { skip: id === "" }
  );

  const { product } = useAppSelector(selectProductState);
  const { name } = product;

  useEffect(() => {
    dispatch(
      setProductData({ property: "product", value: initialProductState })
    );
  }, [navigate]);

  return (
    <>
      <div className="flex justify-content-center">
        <h5 className="text-center">{!id ? "Produkt" : `Produkt ${id}`}</h5>
        <TextInput
          id="name"
          name="name"
          value={name}
          label="Nazwa"
          setProperty={setProductProperty}
        />
      </div>
    </>
  );
};

export default UpdateProduct;
