import React, {FC} from "react";
import TableWrapper from "../../components/table/TableWrapper";
import wtbActionTemplate from "./Actions";
import {useGetAllWtbsQuery, WtbResponse} from "../../api/grailzApi";
import {routes} from "../../app/routes";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";
import {Image} from "primereact/image";

const columns = [
    {field: "name", header: "Nazwa"},
    {field: "priority", header: "Priorytet"},
    {
        field: "imageUrl",
        header: "Podgląd",
        body: (row: WtbResponse) => <Image src={row.imageUrl} width="100"/>,
    },
    {
        field: "",
        header: "",
        body: wtbActionTemplate,
    },
];

const Wtbs: FC<{}> = ({}) => {
    const navigate = useNavigate();
    const {isLoading, data} = useGetAllWtbsQuery();

    const addButton = (
        <>
            <Button onClick={() => navigate(routes.ADD)} label="Dodaj"/>
        </>
    );

    return (
        <>
            <TableWrapper
                header="WTB"
                isLoading={isLoading}
                data={data !== undefined ? data : []}
                columns={columns}
                addButton={addButton}
            />
        </>
    );
};

export default Wtbs;
