import React, { FC } from "react";
import TableWrapper from "../../components/table/TableWrapper";
import walletActionTemplate from "./Actions";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import TextInput from "../../components/form/TextInput";
import { useFindAllWalletProductsQuery } from "../../api/grailzApi";
import { selectWalletState, setWalletData } from "../../slices/walletsSlice";

const columns = [
  { field: "productName", header: "Nazwa produktu" },
  { field: "productSize", header: "Rozmiar" },
  { field: "lastSendProposalDate", header: "Data wysłania ostatniej propozycji" },
  { field: "", header: "Wyślij propozycję", body: walletActionTemplate },
];

const Wallets: FC<{}> = ({}) => {
  const dispatch = useAppDispatch();

  const { page, size, offset, totalSize, filter } =
    useAppSelector(selectWalletState);

  const { data, isLoading } = useFindAllWalletProductsQuery({
    page: page,
    size: size,
    queryText: filter,
  });

  return (
    <>
      <TextInput
        id="filter-wallet"
        name="filter"
        label=""
        value={filter}
        setProperty={setWalletData}
      />
      <TableWrapper
        header="Skarbce użytkowników"
        isLoading={isLoading}
        data={data?.content}
        columns={columns}
        paginator
        page={page}
        first={offset}
        size={size}
        totalElements={totalSize}
        setData={setWalletData}
        addButton={<></>}
      />
    </>
  );
};

export default Wallets;
