import React, { FC } from "react";
import { useAppDispatch } from "../../app/hooks";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import {Dropdown} from "primereact/dropdown";
import {SelectItemOptionsType} from "primereact/selectitem";

type TSelectInputProps = {
    id: string;
    options: SelectItemOptionsType,
    name: string;
    value: string;
    label: string;
    setProperty: ActionCreatorWithPayload<any>;
};

const SelectInput: FC<TSelectInputProps> = ({
                                            id,
    options,
                                            name,
                                            value,
                                            label,
                                            setProperty,
                                        }) => {
    const dispatch = useAppDispatch();

    return (
        <>
            <div className="field field pt-2 pb-2 w-full">
          <span className="p-float-label">
            <Dropdown
                id={id}
                options={options}
                value={value}
                onChange={(e) =>
                    dispatch(
                        setProperty({ property: name, value: e.target.value })
                    )
                }
                className={"w-full"}
            />
            <label htmlFor={name}>{label}</label>
          </span>
            </div>
        </>
    );
};

export default SelectInput;
