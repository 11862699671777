import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {BannerRequest} from "../api/grailzApi";

type TBannerState = {
    genderType: 'MALE' | 'FEMALE' | "DEFAULT" | "GRAIL_POINT";
    banner: BannerRequest;
};

export const initialBannerState: BannerRequest = {
    name: "",
    type: "AFFILIATE_1",
    genderType: "DEFAULT",
    imageUrl: "",
    referralLink: "",
    priority: 0,
    title: '',
    subtitle: '',
    enabled: true,
    shopCategorySlug: '',
    shopProductId: '',
}

const initialState: TBannerState = {
    genderType: 'DEFAULT',
    banner: initialBannerState,
};

export const bannersSlice = createSlice({
    name: "BANNER",
    initialState,
    reducers: {
        setBannerData: <Property extends keyof TBannerState>(
            state: TBannerState,
            {
                payload,
            }: PayloadAction<{ property: Property; value: TBannerState[Property] }>
        ) => {
            const {property, value} = payload;
            state[property] = value;
        },
        setBannerProperty: <Property extends keyof BannerRequest>(
            state: TBannerState,
            {
                payload,
            }: PayloadAction<{ property: Property; value: BannerRequest[Property] }>
        ) => {
            const {property, value} = payload;
            state.banner[property] = value;
        },
    },
});

export const {setBannerData, setBannerProperty} = bannersSlice.actions;

export const selectBannerState = (state: any) => state.banners;

export default bannersSlice.reducer;
