import React, {FC} from "react";
import TableWrapper from "../../components/table/TableWrapper";
import bannerActionTemplate from "./Actions";
import {
    BannerResponse,
    useGetAllBannersQuery,
} from "../../api/grailzApi";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    selectBannerState,
    setBannerData,
    setBannerProperty,
} from "../../slices/bannersSlice";
import {routes} from "../../app/routes";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";
import {RadioButton, RadioButtonChangeParams} from "primereact/radiobutton";
import {Image} from "primereact/image";
import {recognizeLabel} from "../../utils/bannerLabelRecognizer";

const columns = [
    {
        field: "type",
        header: "Typ",
        body: (row: BannerResponse) => recognizeLabel(row.type),
        sortable: true
    },
    {field: "name", header: "Nazwa", sortable: true},
    {field: "priority", header: "Priorytet", sortable: true},
    {
        field: "enabled",
        header: "Włączone w aplikacji",
        body: (row: BannerResponse) => row.enabled ? "Tak" : "Nie",
        sortable: true
    },
    {
        field: "imageUrl",
        header: "Podgląd",
        body: (row: BannerResponse) => <Image src={row.imageUrl} width="100"/>,
    },
    {
        field: "",
        header: "",
        body: bannerActionTemplate,
    },
];

const Banners: FC<{}> = ({}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {genderType} = useAppSelector(selectBannerState);
    const {isLoading, data} = useGetAllBannersQuery({genderType: genderType});

    const onChangeGenderType = (e: RadioButtonChangeParams) => {
        dispatch(
            setBannerData({
                property: "genderType",
                value: e.value,
            })
        );
        dispatch(
            setBannerProperty({
                property: "genderType",
                value: e.value,
            })
        );
    };

    const addButton = (
        <>
            <div className="flex flex-wrap gap-3">
                <div className="flex align-items-center">
                    <RadioButton
                        inputId="ingredient1"
                        name="genderType"
                        value="DEFAULT"
                        onChange={onChangeGenderType}
                        checked={genderType === "DEFAULT"}
                    />
                    <label htmlFor="ingredient1" className="ml-2">
                        Strona główna
                    </label>
                </div>
                <div className="flex align-items-center">
                    <RadioButton
                        inputId="ingredient2"
                        name="genderType"
                        value="FEMALE"
                        onChange={onChangeGenderType}
                        checked={genderType === "FEMALE"}
                    />
                    <label htmlFor="ingredient2" className="ml-2">
                        Promocje damskie
                    </label>
                </div>
                <div className="flex align-items-center">
                    <RadioButton
                        inputId="ingredient3"
                        name="genderType"
                        value="MALE"
                        onChange={onChangeGenderType}
                        checked={genderType === "MALE"}
                    />
                    <label htmlFor="ingredient3" className="ml-2">
                        Promocje męskie
                    </label>
                </div>
                <div className="flex align-items-center">
                    <RadioButton
                        inputId="ingredient4"
                        name="genderType"
                        value="GRAIL_POINT"
                        onChange={onChangeGenderType}
                        checked={genderType === "GRAIL_POINT"}
                    />
                    <label htmlFor="ingredient3" className="ml-2">
                        Sklep
                    </label>
                </div>
            </div>
            <Button onClick={() => navigate(routes.ADD)} label="Dodaj"/>
        </>
    );

    return (
        <>
            <TableWrapper
                header="Banery afiliacyjne i promocje"
                isLoading={isLoading}
                data={data !== undefined ? data : []}
                columns={columns}
                addButton={addButton}
            />
        </>
    );
};

export default Banners;
