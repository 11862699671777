export const routes = {
  HOME: "",
  BANNERS: "banners",
  DROPS: "drops",
  WALLETS: "wallets",
  PRODUCTS: "products",
  WTBS: "wtbs",
  NOTIFICATIONS: "notifications",
  ADD: "add",
  EDIT: "edit",
};
