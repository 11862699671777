import React, {FC, useRef, useState} from "react";
import {useAppDispatch} from "../../app/hooks";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {Calendar} from "primereact/calendar";
import {format, parse} from "date-fns";
import {InputText} from "primereact/inputtext";

type TDateInputProps = {
    id: string;
    name: string;
    value: string;
    label: string;
    dateFormat: string;
    setProperty: ActionCreatorWithPayload<any>;
    index?: number;
};

const DateInput: FC<TDateInputProps> = ({
                                            id,
                                            dateFormat,
                                            name,
                                            value,
                                            label,
                                            setProperty,
                                            index
                                        }) => {
    const dispatch = useAppDispatch();
    let calendarRef = useRef(null);

    const [invalid, setInvalid] = useState<boolean>(false);

    const validateDate = (value: string) => {
        const date = parse(value, "yyyy-MM-dd HH:mm:ss", new Date());

        if (isNaN(date.getTime())) {
            setInvalid(true);
        } else {
            setInvalid(false);
        }
    }

    return (
        <>
            <div className="field pt-2 pb-2 w-full">
        <span className="p-float-label flex">
          <InputText
              id={id + "-input"}
              value={value}
              onChange={(e) => dispatch(
                  setProperty({
                      property: name,
                      value: e.target.value,
                      index: index
                  })
              )}
              onBlur={(e) => validateDate(e.target.value)}
              className={!invalid ? "flex flex-auto" : "flex flex-auto p-invalid"}
          />
            <Calendar
                inputRef={calendarRef}
                inputStyle={{display: 'none'}}
                showIcon
                showTime
                id={id + "-calendar"}
                value={!!value ? parse(value, "yyyy-MM-dd HH:mm:ss", new Date()) : undefined}
                readOnlyInput
                onChange={(e) => {
                    dispatch(
                        setProperty({
                            property: name,
                            // @ts-ignore
                            value: format(e.value, "yyyy-MM-dd HH:mm:ss"),
                            index: index
                        })
                    );
                }}
                className="flex"
            />
          <label htmlFor={name}>{label}</label>
        </span>
                {invalid ? <small id="date-help" style={{color: "#ef9a9a"}}>
                    Nieprawidłowy format daty, użyj yyyy-MM-dd HH:mm:ss
                </small> : null}

            </div>
        </>
    );
};

export default DateInput;
