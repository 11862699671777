import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductResponse } from "../api/grailzApi";

type TProductState = {
  data: ProductResponse[];
  page: number;
  size: number,
  offset: number;
  totalSize: number,
  product: ProductResponse;
  filter: string;
  address: string;
};

export const initialProductState: ProductResponse = {
  id: "",
  brand: "",
  colorway: "",
  name: "",
  primaryCategory: "",
  secondaryCategory: "",
  productCategory: "",
  releaseDate: "",
  retailPrice: 0.0,
  currency: "USD",
  shoe: "",
  shortDescription: "",
  title: "",
  sizeLocale: "",
  year: 0,
  imageUrl: "",
  variants: [],
};

const initialState: TProductState = {
  data: [],
  page: 0,
  size: 10,
  offset: 0,
  totalSize: 0,
  filter: '',
  address: '',
  product: initialProductState,
};

export const productsSlice = createSlice({
  name: "PRODUCT",
  initialState,
  reducers: {
    setProductData: <Property extends keyof TProductState>(
      state: TProductState,
      {
        payload,
      }: PayloadAction<{ property: Property; value: TProductState[Property] }>
    ) => {
      const { property, value } = payload;
      state[property] = value;
    },
    setProductProperty: <Property extends keyof ProductResponse>(
      state: TProductState,
      {
        payload,
      }: PayloadAction<{ property: Property; value: ProductResponse[Property] }>
    ) => {
      const { property, value } = payload;
      state.product[property] = value;
    },
  },
});

export const { setProductData, setProductProperty } = productsSlice.actions;

export const selectProductState = (state: any) => state.products;

export default productsSlice.reducer;
