import Keycloak from "keycloak-js";

const keycloak = new Keycloak({
  url: `${process.env.REACT_APP_KEYCLOAK_URL}/`,
  realm: "grailz",
  clientId: "grail-management-app",
});

const setToken = () => {
};

keycloak.onAuthSuccess = setToken;
keycloak.onAuthRefreshSuccess = setToken;

export default keycloak;
