import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type TSomethingState = { data: any[] };

const initialState: TSomethingState = {
  data: [],
};

export const somethingSlice = createSlice({
  name: "SOMETHING",
  initialState,
  reducers: {
    setData: <Property extends keyof TSomethingState>(
      state: TSomethingState,
      {
        payload,
      }: PayloadAction<{ property: Property; value: TSomethingState[Property] }>
    ) => {
      const { property, value } = payload;
      state[property] = value;
    },
  },
});

export const { setData } = somethingSlice.actions;

export const selectSomethingState = (state: any) => state.somethings;

export default somethingSlice.reducer;
