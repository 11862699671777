import React, { FC } from "react";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Nav from "./components/Navbar";
import { Outlet, Route, Routes } from "react-router-dom";
import Home from "./tabs/home/Home";
import Banners from "./tabs/banner/Banners";
import Drops from "./tabs/drop/Drops";
import Wallets from "./tabs/wallet/Wallets";
import Products from "./tabs/product/Products";
import ProtectedRoute from "./utils/ProtectedRoute";
import { ToastContainer } from "react-toastify";

import "primereact/resources/themes/arya-green/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { useKeycloak } from "@react-keycloak-fork/web";
import { ProgressSpinner } from "primereact/progressspinner";
import { routes } from "./app/routes";
import UpdateBanner from "./tabs/banner/UpdateBanner";
import UpdateDrop from "./tabs/drop/UpdateDrop";
import UpdateProduct from "./tabs/product/UpdateProduct";
import Wtbs from "./tabs/wtb/Wtbs";
import UpdateWtb from "./tabs/wtb/UpdateWtb";
import SendNotifications from "./tabs/bel/SendNotifications";

const App: FC = () => {
  const { initialized } = useKeycloak();

  return (
    <>
      {!initialized ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressSpinner />
        </div>
      ) : (
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Nav />
                <Outlet />
              </ProtectedRoute>
            }
          >
            <Route path={routes.HOME} element={<Outlet />}>
              <Route
                path={`/${routes.HOME}`}
                element={
                  <ProtectedRoute>
                    <Home />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path={routes.BANNERS} element={<Outlet />}>
              <Route
                path={`/${routes.BANNERS}`}
                element={
                  <ProtectedRoute>
                    <Banners />
                  </ProtectedRoute>
                }
              />
              <Route
                  path={`${routes.EDIT}/:id`}
                  element={
                    <ProtectedRoute>
                      <UpdateBanner />
                    </ProtectedRoute>
                  }
              />
              <Route
                  path={`${routes.ADD}`}
                  element={
                    <ProtectedRoute>
                      <UpdateBanner />
                    </ProtectedRoute>
                  }
              />
            </Route>
            <Route path={routes.DROPS} element={<Outlet />}>
              <Route
                path={`/${routes.DROPS}`}
                element={
                  <ProtectedRoute>
                    <Drops />
                  </ProtectedRoute>
                }
              />
              <Route
                  path={`${routes.EDIT}/:id`}
                  element={
                    <ProtectedRoute>
                      <UpdateDrop />
                    </ProtectedRoute>
                  }
              />
              <Route
                  path={`${routes.ADD}`}
                  element={
                    <ProtectedRoute>
                      <UpdateDrop />
                    </ProtectedRoute>
                  }
              />
            </Route>
            <Route path={routes.WALLETS} element={<Outlet />}>
              <Route
                path={`/${routes.WALLETS}`}
                element={
                  <ProtectedRoute>
                    <Wallets />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path={routes.PRODUCTS} element={<Outlet />}>
              <Route
                path={`/${routes.PRODUCTS}`}
                element={
                  <ProtectedRoute>
                    <Products />
                  </ProtectedRoute>
                }
              />
              <Route
                  path={`:id`}
                  element={
                    <ProtectedRoute>
                      <UpdateProduct />
                    </ProtectedRoute>
                  }
              />
            </Route>
            <Route path={routes.WTBS} element={<Outlet />}>
              <Route
                  path={`/${routes.WTBS}`}
                  element={
                    <ProtectedRoute>
                      <Wtbs />
                    </ProtectedRoute>
                  }
              />
              <Route
                  path={`${routes.EDIT}/:id`}
                  element={
                    <ProtectedRoute>
                      <UpdateWtb />
                    </ProtectedRoute>
                  }
              />
              <Route
                  path={`${routes.ADD}`}
                  element={
                    <ProtectedRoute>
                      <UpdateWtb />
                    </ProtectedRoute>
                  }
              />
            </Route>
            <Route path={routes.NOTIFICATIONS} element={<Outlet />}>
              <Route
                  path={`/${routes.NOTIFICATIONS}`}
                  element={
                    <ProtectedRoute>
                      <SendNotifications />
                    </ProtectedRoute>
                  }
              />
            </Route>
          </Route>
        </Routes>
      )}
      <ToastContainer theme="dark" />
    </>
  );
};

export default App;
