import React, {FC} from "react";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import {ConfirmDialog} from "primereact/confirmdialog";
import {useSendProposalAboutWalletProductMutation} from "../../api/grailzApi";

const Actions: FC<{ row: any }> = ({row}) => {
    const [visible, setVisible] = React.useState(false);

    const [sendProposal] = useSendProposalAboutWalletProductMutation();

    return (
        <>
            <div style={{flexDirection: "row"}}>
                <Button icon={PrimeIcons.SEND} onClick={() => setVisible(true)}/>
                <ConfirmDialog
                    visible={visible}
                    onHide={() => setVisible(false)}
                    message="Jesteś pewny, że chcesz wysłać użytkownikowi powiadomienie z propozycją odkupienia produktu?"
                    header="Potwierdzenie"
                    acceptLabel="Tak"
                    rejectLabel="Nie"
                    accept={() => sendProposal({id: row.id})}
                />
            </div>
        </>
    );
};

const walletActionTemplate = (row: any) => <Actions row={row}/>;
export default walletActionTemplate;
