import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WalletProductResponse } from "../api/grailzApi";

type TWalletState = {
  data: WalletProductResponse[];
  page: number;
  size: number;
  offset: number;
  totalSize: number;
  filter: string;
};

const initialState: TWalletState = {
  data: [],
  page: 0,
  size: 10,
  offset: 0,
  totalSize: 0,
  filter: "",
};

export const walletsSlice = createSlice({
  name: "WALLET",
  initialState,
  reducers: {
    setWalletData: <Property extends keyof TWalletState>(
      state: TWalletState,
      {
        payload,
      }: PayloadAction<{ property: Property; value: TWalletState[Property] }>
    ) => {
      const { property, value } = payload;
      state[property] = value;
    },
  },
});

export const { setWalletData } = walletsSlice.actions;

export const selectWalletState = (state: any) => state.wallets;

export default walletsSlice.reducer;
