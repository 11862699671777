import React, {FC, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    initialCustomNotificationState,
    selectNotificationDataState,
    setData,
    setDataProperty
} from "../../slices/notificationsSlice";
import TextInput from "../../components/form/TextInput";
import TextAreaInput from "../../components/form/TextAreaInput";
import {Button} from "primereact/button";
import {useSendCustomNotificationMutation} from "../../api/grailzApi";
import {ConfirmDialog} from "primereact/confirmdialog";
import {toast} from "react-toastify";

const SendNotifications: FC<{}> = ({}) => {
    const dispatch = useAppDispatch();
    const {title, subTitle, description} = useAppSelector(selectNotificationDataState);
    const [confirmationDialogVisible, setConfirmationDialogVisible] = React.useState(false);

    const [sendNotification, {isSuccess}] = useSendCustomNotificationMutation();

    const onSubmit = () => {
        sendNotification({
            customNotificationRequest: {
                title: title,
                subTitle: subTitle,
                description: description,
                data: {}
            }
        });
    };

    useEffect(() => {
        if (isSuccess) {
            dispatch(setData({property: 'data', value: initialCustomNotificationState}));
            toast.success("Powiadomienie zostało wysłane!");
        }
    }, [isSuccess]);

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    margin: 10
                }}
            >
                <ConfirmDialog
                    visible={confirmationDialogVisible}
                    onHide={() => setConfirmationDialogVisible(false)}
                    message="Jesteś pewny, że chcesz wysłać to powiadomienie?"
                    header="Potwierdzenie"
                    acceptLabel="Tak"
                    rejectLabel="Nie"
                    accept={onSubmit}
                />
                <h2 style={{margin: 10}}>Wyślij customową notyfikacje</h2>
                <h4 style={{margin: 10}}>Uwaga! Powiadomienie zostanie wysłane do wszystkich zalogowanych użytkowników aplikacji! (powiadomienie będzie widoczne na jego liście powiadomień)</h4>
                <div>
                    <div className="grid m-5">
                        <TextInput
                            id="title"
                            name="title"
                            label="Tytuł"
                            value={title}
                            setProperty={setDataProperty}
                            style={{width: "100%"}}
                        />
                        <TextInput
                            id="subTitle"
                            name="subTitle"
                            label="Podtytuł"
                            value={subTitle}
                            setProperty={setDataProperty}
                            style={{width: "100%"}}
                        />
                        <TextAreaInput
                            id="description"
                            name="description"
                            label="Opis"
                            value={description}
                            setProperty={setDataProperty}
                            style={{width: "100%"}}
                        />
                        <Button label="Wyślij powiadomienie" className="mt-5 w-full"
                                onClick={() => setConfirmationDialogVisible(true)}/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SendNotifications;
