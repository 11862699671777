import { useKeycloak } from "@react-keycloak-fork/web";
import { Outlet } from "react-router-dom";
import React, { FC, ReactNode } from "react";

export const ProtectedRoute: FC<{ children: ReactNode }> = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();

  const isLoggedIn = keycloak.authenticated;

  if (initialized && !isLoggedIn) {
    keycloak.login();
  }

  return <>{isLoggedIn ? children : <Outlet />}</>;
};

ProtectedRoute.displayName = "ProtectedRoute";
export default ProtectedRoute;
