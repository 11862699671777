import React, { FC } from "react";

const Home: FC = () => {
  return (
    <div>
      <h1 className="text-4xl">Grailz management</h1>
    </div>
  );
};

export default Home;
